exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".alerts_alerts-inner-container_h6dBI {\r\n    min-width: 200px;\r\n    max-width: 548px;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"alerts-inner-container": "alerts_alerts-inner-container_h6dBI",
	"alertsInnerContainer": "alerts_alerts-inner-container_h6dBI"
};