exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\r\n.login-dropdown_login_82DKk {\r\n    padding: .625rem;\r\n}\r\n", ""]);

// exports
exports.locals = {
	"login": "login-dropdown_login_82DKk"
};